<template>
  <Form ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="100" style="width: 600px;margin: 30px auto;">
    <!-- <FormItem label="激活码">
      <h3 style="color: red;">
        {{ code }}
      </h3>
    </FormItem> -->
    <FormItem label="密码" prop="password">
      <Input type="password" v-model="formCustom.password" placeholder="请输入密码"></Input>
    </FormItem>
    <FormItem label="确认密码" prop="passwordCheck">
      <Input type="password" v-model="formCustom.passwordCheck" placeholder="请再次输入密码"></Input>
    </FormItem>
    <FormItem>
      <Button type="primary" @click="handleSubmit('formCustom')">
        确定
      </Button>
      <Button @click="handleReset('formCustom')" style="margin-left: 8px">
        重置
      </Button>
    </FormItem>
  </Form>
</template>
<script>
  const { mapActions } = window.Vuex;
  import AppConfig from '@/app.config';
  export default {
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请确认你的密码"));
        } else {
          if (this.formCustom.passwordCheck !== "") {
            // 对第二个密码框单独验证
            this.$refs.formCustom.validateField("passwordCheck");
          }
          callback();
        }
      };
      const validatePassCheck = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次确认你的密码"));
        } else if (value !== this.formCustom.password) {
          callback(new Error("两次密码输入不一致"));
        } else {
          callback();
        }
      };
      return {
        code: '',
        formCustom: {
          password: "",
          passwordCheck: "",
        },
        ruleCustom: {
          password: [{ validator: validatePass, trigger: "blur" }],
          passwordCheck: [{ validator: validatePassCheck, trigger: "blur" }],
        }
      };
    },
    created () {
      this.rmSession();
    },
    mounted () {
      const params = this.$route.params;
      this.code = params.code;
    },
    methods: {
      ...mapActions([
        "rmSession",
      ]),
      handleSubmit(name) {
        const params = this.$route.params;
        this.code = params.code;
        this.$refs[name].validate(valid => {
          if (valid) {
            this
              .$request
              .put('/manage/user/active', {
                code: params.code,
                ...this.formCustom,
                tenantId: params.tenant
              })
              .then(() => {
                window.location.href= `${AppConfig.publicPath}/login?tenant=${params.tenant}&tp=${new Date().valueOf()}`;
              });
          }
        });
      },
      handleReset(name) {
        this.$refs[name].resetFields();
      }
    }
  };
</script>

<style lang="less">
  .active {
    font-size: 20px;
    padding-top: 30px;
  }
</style>
